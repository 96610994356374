import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import smallPlus from "../images/0.0 Repeating Modules/Plus icon small.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="About DeBlanc + Murphy | Washington Metro Area CPA Firm"
        description="DeBlanc + Murphy is a full-service CPA firm founded in 1978. We’re based in La Plata, Maryland, serving the greater Washington, DC, area. Call us today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-20 pt-14 md:mb-32 md:pt-10">
        <div className="container">
          <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>DeBlanc, Murphy & Murphy</h1>
              <p className="md:text-xl">
              DeBlanc, Murphy & Murphy is a full-service CPA firm founded in 1978 with
                a desire to help clients grow and succeed. Serving the greater Washington, DC area.
              </p>
              <ButtonSolid
                modal="modal-contact"
                text="Let’s Start a Conversation"
                className="w-full md:w-auto"
              />
              
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/4.0 About/1.0 Hero.jpg"
                loading="eager"
                width={560}
                className="rounded-tl-6xl rounded-br-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="relative -mx-4 mb-20 px-14 pb-20 pt-32 md:mx-0 md:mb-32 md:pt-7 md:pb-16">
          <div className="absolute left-0 top-0 hidden h-full w-full md:block">
            <StaticImage
              src="../images/4.0 About/2.0 Our Mission.png"
              loading="lazy"
              className="h-full rounded-6xl"
            />
          </div>
          <div className="absolute left-0 top-0 h-full w-full md:hidden">
            <StaticImage
              src="../images/4.0 About/2.0 Our Mission mobile.png"
              loading="lazy"
              className="h-full rounded-6xl"
            />
          </div>

          <header className="relative mx-auto max-w-[720px] text-center">
            <h2>Our Mission</h2>
            <p className="mb-0 md:text-xl">
              We partner with our clients, taking the time to understand goals,
              answer questions, develop smart strategies, and formulate
              innovative solutions. Our primary objectives are your success and
              growth, and we always welcome a good challenge. We want you to be
              well-informed plus feel confident in the work we do for you and
              the strategies we help you employ.
            </p>
          </header>
        </section>
      </div>

      <section className="mb-24 md:mb-40">
        <div className="container">
          <header className="mb-14 md:mb-20">
            <h2>What You Get</h2>
          </header>

          <div className="grid items-center justify-between gap-y-6 md:flex md:space-x-10 lg:space-x-16">
            <div>
              <h3 className="font-normal">The Best Tools & Advice</h3>
              <p className="mb-0">
                We put sophisticated tax planning tools and expert advice to
                work for you so you get the most out of life.
              </p>
            </div>

            <img
              src={smallPlus}
              width={45}
              alt="Small plus sign"
              className="mx-auto md:mx-0"
            />

            <div>
              <h3 className="font-normal">Your Problems, Solved</h3>
              <p className="mb-0">
                We optimize your books and take the stress out of taxes,
                compliance, and financial issues impacting your business.
              </p>
            </div>

            <img
              src={smallPlus}
              width={45}
              alt="Small plus sign"
              className="mx-auto md:mx-0"
            />

            <div>
              <h3 className="font-normal">A Better Way to Pay</h3>
              <p className="mb-0">
                We’re different from other CPA firms. You pay for the value we
                provide, not the hours we put in.
              </p>
            </div>
          </div>
        </div>
      </section>

      <About />
      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

// export const data = graphql`
// {

//  }
// `;

export default Page;
